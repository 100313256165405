<template>
    <div>
        <wj-upload
            action=""
            class="activity-uploader"
            :show-file-list="false"
            :before-upload="beforeUpload"
            :http-request="handleFileUpload"
        >
            <div class="uploader-container">
                <template v-if="images">
                    <img :src="images" class="avatar" />
                </template>
                <template v-else>
                    <i class="wj-icon-plus activity-uploader-icon"></i>
                    <span class="">上传封面</span>
                </template>
            </div>
        </wj-upload>
    </div>
</template>
<script>
import { Upload } from '@wenjuan/ui';
// import axios from 'axios';
// import { httpGetuploadToken, httpGetGoodsuploadToken } from '@/http/common/index.js';
export default {
    name: 'upload',
    props: ['images', 'type'],
    model: {
        prop: 'images',
        event: 'fn',
    },
    data() {
        return {};
    },
    methods: {
        //上传前校验
        beforeUpload(file) {
            let type = this.getSuffix(file.name).toLowerCase();
            let isImg = ['.png', '.jpg', '.jpeg'].includes(type);
            let size = file.size < 2 * 1024 * 100;
            if (!isImg) {
                this.$message({
                    message: '不符合要求的图片类型！',
                    type: 'warning',
                    duration: 2000,
                });
                return false;
            }
            if (!size) {
                this.$message({
                    message: '图片不能超过200kb！',
                    type: 'warning',
                    duration: 2000,
                });
                return false;
            }
        },
        //自定义上传行为
        handleFileUpload(file) {
            this.getBase64(file).then((res) => {
                this.$emit('fn', res);
            });
            // this.getUploadUrl(file.file.name).then((res) => {
            //     let formData = new FormData();
            //     let headers = {
            //         'Content-Type': 'multipart/form-data',
            //     };
            //     var url = res.host;
            //     var axios_method = axios.post;
            //     let imgUrl = `${url}/${res.file_key}`;
            //     if (res.access_key) {
            //         formData.append('key', res.file_key); // 存储在oss的文件路径和名称
            //         formData.append('OSSAccessKeyId', res.access_key); //accessKeyId
            //         formData.append('policy', res.policy); // policy
            //         formData.append('signature', res.signature); //签名
            //     } else {
            //         axios_method = axios.put;
            //         url = `${url}/${res.file_key}`;
            //         Object.keys(res.headers).forEach((key) => {
            //             formData.append(key, res.headers[key]);
            //         });
            //     }
            //     formData.append('name', file.file.name); // 文件名称
            //     formData.append('success_action_status', 200);
            //     formData.append('file', file.file, file.file.name); // 如果是base64文件，那么直接把base64字符串转成blob对象进行上传即可
            //     axios_method(`${url}`, formData, {
            //         headers: headers,
            //         // 文件上传中
            //         onUploadProgress: () => {},
            //         // 取消上传
            //         cancelToken: new axios.CancelToken(function executor(c) {
            //             self.source = c;
            //         }),
            //     }).then((success) => {
            //         console.log('file upload OSS success -- ', success);
            //         this.$emit('fn', [imgUrl]);
            //         this.$emit('success');
            //     });
            // });
        },
        // getUploadUrl(name) {
        //     if (this.type == 'goods') {
        //         return httpGetGoodsuploadToken(name);
        //     } else {
        //         return httpGetuploadToken(name);
        //     }
        // },
        // 截取上传文件后缀
        getSuffix(filename) {
            let pos = filename.lastIndexOf('.');
            let suffix = '';
            if (pos != -1) suffix = filename.substring(pos);
            return suffix;
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                let result = '';
                const reader = new FileReader();
                reader.readAsDataURL(file.file);
                reader.onload = function () {
                    result = reader.result;
                };
                reader.onerror = function (error) {
                    reject(error);
                };
                reader.onloadend = function () {
                    resolve(result);
                };
            });
        },
    },
    components: {
        'wj-upload': Upload,
    },
};
</script>
<style lang="scss" scoped>
.activity-uploader {
    ::v-deep .wj-upload {
        width: 160px;
        height: 92px;
        border: 1px dashed #c0ccda;
        .uploader-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: #aaaaaa;
            height: 100%;
            line-height: 20px;
            font-size: 12px;
            .activity-uploader-icon {
                font-size: 20px;
            }
        }
    }
    .avatar {
        width: 100%;
        height: 100%;
    }
}
</style>
