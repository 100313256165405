<template>
    <!-- 主内容 -->
    <div class="course-main">
        <Advert v-if="!isMobile"></Advert>
        <div class="course-main-wrap">
            <div class="course-main-header flex y-center x-between mb24">
                <div class="course-main-header-left flex y-center">
                    <div class="header-desc mr40 f18">{{ schoolName }}</div>
                    <div class="header-bread flex y-center">
                        <div v-if="!isMobile" class="header-bread-text f14 mr8">当前位置：</div>
                        <wj-breadcrumb separator-class="wj-icon-arrow-right">
                            <!-- <wj-breadcrumb-item :to="{ path: '/' }"
                                        >首页</wj-breadcrumb-item
                                    > -->
                            <wj-breadcrumb-item :to="{ path: '/' }">首页</wj-breadcrumb-item>
                            <wj-breadcrumb-item>{{
                                schoolName ? schoolName : '课程管理'
                            }}</wj-breadcrumb-item>
                        </wj-breadcrumb>
                    </div>
                </div>
                <wj-link
                    v-if="isManage"
                    :underline="false"
                    type="primary"
                    class="f16"
                    @click="hanldeClickToShowAddDialog"
                >
                    新增课程
                </wj-link>
            </div>
            <div class="course-main-content flex" v-loading="loading">
                <template v-if="courseList.length">
                    <CourseItem
                        v-for="(item, index) in courseList"
                        :key="index"
                        :itemData="item"
                        renderType="course"
                        @editFn="editCourse"
                        @delFn="delCourse"
                        :img="imgList[index]"
                        :isEdit="isManage"
                        :isMobile="isMobile"
                    />
                </template>

                <div class="empty-area" v-if="courseList.length < 1 && !loading">
                    <wj-image :src="require('@/assets/images/empty-data.png')"></wj-image>
                    <span class="sign">暂无课程</span>
                </div>
            </div>
            <!-- 分页 -->
            <div class="course-main-footer" v-if="courseList.length">
                <wj-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="current"
                    :page-size="size"
                    layout="prev, pager, next, jumper"
                    :total="total"
                    :hide-on-single-page="true"
                >
                </wj-pagination>
            </div>
        </div>
        <!-- 新增/编辑 -->
        <wj-dialog
            custom-class="add-container"
            :visible.sync="showDialogAdd"
            width="432px"
            :title="operationType == 1 ? '新增高校课程' : '编辑高校课程'"
            :show-close="false"
            :close-on-click-modal="false"
            v-if="showDialogAdd"
        >
            <wj-form ref="addFormRef" :model="addFormRef" label-width="80px" :rules="rules">
                <wj-form-item label="封面：" class="cover-info" prop="cover">
                    <!-- <wj-input v-model="addFormRef.cover"></wj-input> -->
                    <Upload v-model="addFormRef.cover"></Upload>
                    <span class="point">建议尺寸：310*180</span>
                </wj-form-item>
                <wj-form-item label="标题：" prop="title">
                    <wj-input v-model="addFormRef.title" placeholder="请输入课程标题"></wj-input>
                </wj-form-item>
                <wj-form-item>
                    <wj-button type="primary" @click="addCourse" v-if="operationType == 1"
                        >立即创建</wj-button
                    >
                    <wj-button type="primary" @click="doEditCourse" v-else>确定</wj-button>
                    <wj-button @click="showDialogAdd = false">取消</wj-button>
                </wj-form-item>
            </wj-form>
        </wj-dialog>
    </div>
</template>

<script>
import CourseItem from '@/pages/components/course-item.vue';
import { httpGetCourseList } from '@/http/user/index.js';
import {
    httpPostAdminCourse,
    httpDeleteAdminCourse,
    httpPutUpdateCourse,
} from '@/http/admin/index.js';
import {
    Button,
    Breadcrumb,
    BreadcrumbItem,
    Dialog,
    Form,
    FormItem,
    Image,
    Input,
    Pagination,
    Link,
} from '@wenjuan/ui';
import { httpGetImageList } from '@/http/public/index.js';
import Advert from '@/layout/advert.vue';
import Upload from '@/components/upload/index.vue';
export default {
    name: 'course',
    components: {
        CourseItem,
        'wj-form': Form,
        'wj-form-item': FormItem,
        'wj-breadcrumb': Breadcrumb,
        'wj-breadcrumb-item': BreadcrumbItem,
        'wj-button': Button,
        'wj-dialog': Dialog,
        'wj-image': Image,
        'wj-input': Input,
        'wj-pagination': Pagination,
        'wj-link': Link,
        Advert,
        Upload,
    },
    data() {
        return {
            courseList: [],
            // courseId: 0,
            showDialogAdd: false, //模态框 新增
            addFormRef: {
                title: '',
                cover: '',
            },
            title: '',
            current: 1,
            size: 8,
            total: 0,
            imgList: [],
            type: null, //页面类型 true：课程管理
            loading: false,
            schoolName: '',
            rules: {
                cover: [{ required: true, message: '请输入上传课程封面' }],
                title: [{ required: true, message: '请输入课程标题', trigger: 'blur' }],
            },
            operationType: 1, //操作类型1：新增 2：编辑
            operationId: '',
            isMobile: false,
        };
    },
    created() {
        if (
            navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            )
        ) {
            this.isMobile = true;
        }
        this.type = this.$route.path.includes('/admin') ? 1 : 0;
        this.schoolName = this.$route.query.name;
        this.getCourseList({
            current: 0,
            size: this.size,
            title: this.title,
        });
    },
    watch: {
        'addFormRef.cover'() {
            this.$refs.addFormRef.validateField('cover');
        },
    },
    mounted() {},
    computed: {
        isAdmin() {
            return this.$store.state.userType === 'ADMIN';
        },
        isManage() {
            return this.isAdmin && this.type;
        },
    },
    methods: {
        // 封装 获取课程列表
        async getCourseList(pageInfo) {
            this.loading = true;
            try {
                let res = await httpGetCourseList(pageInfo);
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                let courseList = res.data.data;
                let imgList = courseList.map((item) => {
                    return item.coverImageId;
                });
                let total = Number(res.data.total);
                if (imgList.length) {
                    let imgItem = await this.getImageList(imgList);
                    this.imgList = imgItem;
                }
                this.courseList = courseList;
                this.total = total;
                this.loading = false;
            } catch {
                this.loading = false;
            }
        },
        // 点击分页按钮 获取列表
        handleCurrentChange(current) {
            this.getCourseList({
                current: current - 1,
                size: this.size,
                title: this.title,
            });
        },
        // 新增课程 弹窗
        hanldeClickToShowAddDialog() {
            this.addFormRef = {
                title: '',
                cover: '',
            };
            this.operationType = 1;
            this.showDialogAdd = true;
        },
        // 新增课程 请求
        addCourse() {
            this.$refs['addFormRef'].validate((valid) => {
                if (valid) {
                    httpPostAdminCourse(this.addFormRef).then(() => {
                        this.showDialogAdd = false;
                        this.getCourseList({
                            current: this.current - 1,
                            size: this.size,
                            title: this.title,
                        });
                        this.addFormRef = {
                            title: '',
                            cover: '',
                        };
                        this.$message({
                            message: '新增成功',
                            type: 'success',
                            duration: 2000,
                        });
                    });
                }
            });
        },
        // 编辑课程 请求
        editCourse(course) {
            this.operationType = 2;
            this.showDialogAdd = true;
            this.addFormRef = {
                title: course.title,
                cover: course.coverImage,
            };
            this.operationId = course.courseId;
        },
        doEditCourse() {
            this.$refs['addFormRef'].validate((valid) => {
                if (valid) {
                    let params = {
                        ...this.addFormRef,
                        courseId: this.operationId,
                    };
                    httpPutUpdateCourse(params).then(() => {
                        this.showDialogAdd = false;
                        this.getCourseList({
                            current: this.current - 1,
                            size: this.size,
                            title: this.title,
                        });
                        this.$message({
                            message: '编辑成功',
                            type: 'success',
                            duration: 2000,
                        });
                    });
                }
            });
        },
        // 删除课程 请求
        delCourse(courseId) {
            this.$confirm('确定删除此课程吗？', '删除课程', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            })
                .then(() => {
                    httpDeleteAdminCourse(courseId).then(() => {
                        let current = this.current - 1;
                        if (current > 0 && this.courseList.length == 1) {
                            current = current - 1;
                        }
                        this.getCourseList({
                            current,
                            size: this.size,
                            title: this.title,
                        });
                        this.$message({
                            message: '删除成功',
                            type: 'success',
                            duration: 2000,
                        });
                    });
                })
                .catch(() => {});

            // console.log('删除');
        },
        // 已登录 直接去分析页
        goToDataAnaly() {
            this.$router.push('/analysis/index');
        },
        getImageList(list) {
            return new Promise((resolve, reject) => {
                httpGetImageList(list)
                    .then((res) => {
                        resolve(res.data.data);
                    })
                    .catch(() => {
                        reject();
                    });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.course-main {
    background-color: #f3f5f7;
    // 版心布局
    .course-main-wrap {
        width: 1330px;
        margin: 0 auto;
        @media (max-width: 640px) {
            width: 100%;
        }
        .course-main-header {
            padding: 30px 0 5px 0;
            @media (max-width: 640px) {
                padding: 36px 24px 0;
                margin-bottom: 30px;
            }
            .course-main-header-left {
                @media (max-width: 640px) {
                    flex-direction: column;
                    align-items: flex-start;
                }
                .header-desc {
                    font-family: PingFangSC;
                    color: #3c3c3c;
                    @media (max-width: 640px) {
                        font-weight: bold;
                        line-height: 22px;
                        font-family: PingFangSC-Medium;
                        margin-bottom: 8px;
                        margin-right: 0;
                    }
                }
                .header-bread {
                    @media (max-width: 640px) {
                        .wj-breadcrumb {
                            line-height: 17px;
                            font-size: 12px;
                            ::v-deep .wj-breadcrumb__inner {
                                color: #8f8f8f;
                            }
                        }
                    }
                }
            }
            .course-main-header-left {
                color: #707070;
                @media (max-width: 640px) {
                    color: #8f8f8f;
                }
            }
        }
        .course-main-content {
            width: 1330px;
            min-height: 560px;
            flex-wrap: wrap;
            @media (max-width: 640px) {
                width: 100%;
                min-height: 400px;
                padding: 0 24px;
                box-sizing: border-box;
                justify-content: space-between;
            }
            .empty-area {
                width: 100%;
                height: 560px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                @media (max-width: 640px) {
                    height: auto;
                }
                .wj-image {
                    width: 250px;
                    height: 150px;
                }
                .sign {
                    margin-top: 15px;
                    color: #666666;
                }
            }
        }
        .course-main-footer {
            text-align: center;
            // 去掉边框
            .wj-pagination li,
            .wj-pagination button {
                background-color: #f3f5f7;
                font-size: 14px;
                color: #3c3c3c;
                font-family: PingFangSC;
                &:not(.active) {
                    border: 1px solid rgba(255, 255, 255, 0);
                }
            }
            .wj-pagination input,
            .wj-pagination__jump {
                background-color: #f3f5f7;
                border-radius: 3px;
                font-size: 14px;
                color: #3c3c3c;
                font-family: PingFangSC;
            }
        }
    }
}
.add-container {
    .cover-info {
        ::v-deep .wj-form-item__content {
            display: flex;
            align-items: center;
            .point {
                color: #aaaaaa;
                font-size: 12px;
                margin-left: 10px;
            }
        }
    }
    .wj-form {
        ::v-deep .wj-form-item__label {
            text-align: left;
        }
    }
}
</style>
